'use strict'

// var barbaLoad = require('../../../node_modules/barba.js/');
// global.barbaLoad = barbaLoad;

// var barbaInit = require('../modules/barba_init'),
var lazyload = require('jquery-lazy'),
	lazyloadPlugin = require('../../../node_modules/jquery-lazy/jquery.lazy.plugins'),
	imgLoader = require('../modules/lazy'),
	smInit = require('scrollmagic'),
	animation = require('../../../node_modules/scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js'),
	tmInit = require('../../../node_modules/gsap/src/minified/TimelineMax.min.js')

global.smInit = smInit
global.animation = animation
global.tmInit = tmInit

var siteGlobal = {
	initReady: function () {
		// this.barbaJsInit();
		this.menuInit()
		this.countdownInit()
	},
	initLoad: function () {
		this.lazyLoad()
		this.linkAll()
		this.menuActiveLink()
		this.filtersInit()
		this.infiniteScrollBind()
		this.scrollToTop()
	},
	initResize: function () {},
	initResizeEnd: function () {},
	initScroll: function () {
		this.headerScroll()
	},
	onScroll: function () {},
	barbaJsInit: function () {
		barbaInit.init()
	},
	linkAll: function () {
		$('.linkFrom').each(function () {
			var elemToLink = $(this).parents('.linkTo')
			var pageLink = $(this).attr('href')
			var linkTarget = typeof $(this).attr('target') !== 'undefined' ? $(this).attr('target') : ''
			if (pageLink !== undefined && pageLink !== 'javascript:;') {
				elemToLink.addClass('pointer').click({ myLink: pageLink, myTarget: linkTarget }, function (e) {
					e.preventDefault()
					if (e.data.myTarget === '_blank') {
						window.open(e.data.myLink)
					} else {
						window.location.href = e.data.myLink
					}
				})
			}
		})
	},
	lazyLoad: function () {
		imgLoader.lazyInit()
	},
	formsCheck: function () {
		const loader = $(this.container).find('.ajax-loader')
		if (!loader.length) {
			$('div.wpcf7 > form').each(function () {
				$(this).wpcf7InitForm()
			})
		}
	},
	headerScroll: function () {
		var winScroll = $(window).scrollTop()
		if (winScroll > 0) {
			$('.header').addClass('scrolled')
		} else {
			$('.header').removeClass('scrolled')
		}
	},
	inputWrap: function () {
		$('[type="submit"]').wrap('<div class="submitWrapper"></div>')
		$('[type="checkbox"]').each(function () {
			if ($(this).siblings().length) {
				return false
			} else {
				$(this).after('<label></label>')
			}
		})

		$('li.no-barba > a').addClass('no-barba')
	},
	menuActiveLink: function () {
		$('a').on('click', function () {
			var data_link = $(this).data('link')
			if (data_link != '') {
				$('nav li').removeClass('current_page_item')
				$('.' + data_link).addClass('current_page_item')
			}
		})

		$('.header nav a').on('click', function () {
			$('nav li').removeClass('current_page_item')
			$(this).parent().addClass('current_page_item')
		})
	},
	menuInit: function (evt) {
		$('.header__hamburger, .menuWrapper__flex').on('click', function () {
			$('.hamburger').toggleClass('open is-active')
			$('.menuBg').toggleClass('opened')
		})

		$(document).on('keydown', function (e) {
			if (e.keyCode == 27) {
				$('.hamburger').toggleClass('open is-active')
				$('.menuBg').toggleClass('opened')
			}
		})
	},
	scrollToTop: function () {
		$('.scrollTop').on('click', function () {
			event.preventDefault()
			$('html, body').animate(
				{
					scrollTop: 0,
				},
				500
			)
		})
	},
	filtersInit: function () {
		$('.filterButton').on('click', function () {
			$(this).toggleClass('activeFilter')
		})
	},
	infiniteScrollBind: function () {
		$(window).on('yith_infs_adding_elem', function () {
			siteGlobal.lazyLoad()
		})
	},
	countdownInit: function () {
		var countDownDate = new Date('Sep 1, 2017 10:00:00').getTime()
		if ($('#countdown-full').length) {
			// Update the count down every 1 second
			var x = setInterval(function () {
				// Get todays date and time
				var now = new Date().getTime()

				// Find the distance between now an the count down date
				var distance = countDownDate - now

				// Time calculations for days, hours, minutes and seconds
				var days = Math.floor(distance / (1000 * 60 * 60 * 24))
				var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
				var seconds = Math.floor((distance % (1000 * 60)) / 1000)

				document.getElementById('countdown-full').innerHTML =
					"<div class='cd__wrapper'><div class='cd__date'><span>" +
					days +
					"</span><small class='text-uppercase'>giorni</small></div><span class='cd__sep'></span><div class='cd__date'><span>" +
					hours +
					"</span><small class='text-uppercase'>ore</small></div><span class='cd__sep'></span><div class='cd__date'><span>" +
					minutes +
					"</span><small class='text-uppercase'>minuti</small></div><span class='cd__sep'></span><div class='cd__date'><span>" +
					seconds +
					"</span><small class='text-uppercase'>secondi</small></div>"

				// If the count down is over, write some text
				if (distance < 0) {
					clearInterval(x)
					document.getElementById('countdown').innerHTML = 'EXPIRED'
				}
			}, 1000)
		}
	},
	rebuildAllEvents: function () {
		this.initLoad()
		this.inputWrap()
		this.formsCheck()
	},
}

global.siteGlobal = siteGlobal
;(function () {
	siteGlobal.initReady()

	$(window).on('load', function () {
		siteGlobal.initLoad()
		siteGlobal.inputWrap()
		$('body, #preloader').addClass('loaded')
	})

	$(window).resize(function () {
		siteGlobal.initResize()
	})

	if (typeof $(window).resizeend === 'function') {
		$(window).resizeend(function () {
			siteGlobal.initResizeEnd()
		})
	} else {
		$(window).resize(function () {
			siteGlobal.initResizeEnd()
		})
	}

	$(window).scroll(function () {
		siteGlobal.initScroll()
	})

	$(window).on('scroll', function () {
		siteGlobal.onScroll()
	})
})()
